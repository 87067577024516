@charset "UTF-8";

@import "breakpoint";

// !!! check path
@import "../../../drupal/8-prod/themes/material_base/sass/reset";
@import "../../../drupal/8-prod/themes/material_base/sass/color-palette";
@import "variables";
@import "../../../drupal/8-prod/themes/material_base/sass/variables";
@import "../../../drupal/8-prod/themes/material_base/sass/modules";

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&subset=cyrillic,cyrillic-ext');

/* layout */

html, body, #page, #navbar { min-width: 320px; }

@include breakpoint($lg + 24px) {
  .navbar-content, .footer-content { margin-left: auto; margin-right: auto; max-width: $lg; padding: 0 $gutter / 2; }
}

/* common styles */

h1, .h1, h2, .h2, h3, .h3, h4, .h4 { color: $color-accent; font-weight: 600; }
h1, .h1, h2, .h2 { text-transform: uppercase; }

.btn { font-weight: 600; }

.block-pt { padding-top: 64px; }
.block-pb { padding-bottom: 64px; }
.block-mb { margin-bottom: 32px; }
.block-mt { margin-top: 32px; }

@include breakpoint($xl) {
  .block-pt { padding-top: 96px; }
  .block-pb { padding-bottom: 96px; }
  .block-mb { margin-bottom: 64px; }
  .block-mt { margin-top: 64px; }
}

.block-title { text-align: center; }

.photoswipe { position: relative;
  &:after { @extend .material-icons; content: "zoom_in"; position: absolute; right: 12px; top: 12px; color: rgba($color-text-light, .75); }
}

/* navbar */

.navbar-slogan { padding: 12px;
  &.multiline { display: flex; flex-direction: column; font-size: 11px; text-align: left; line-height: 14px; padding-top: 10px; padding-bottom: 10px; text-transform: uppercase; }
}

.navbar-phone-icon { margin: 0 -24px 0 -12px;
  .navbar-icon { margin-right: 4px; }
}

.navbar-phone { height: 48px; margin: 0 -12px; padding: 12px 12px 12px 12px; font-size: 16px; line-height: 24px; 
  a { color: $color-text-light; font-weight: 600; font-size: 18px; line-height: 24px; }
}

.navbar-menu .menu a { padding-left: 8px; padding-right: 8px; }

@include breakpoint($lg) {
  .navbar-phone {
    a { font-size: 20px; }
  }
}

/* header */

.icons-src-white .services-icons { background-image: url('../img/services-icons-white.svg'); }

#header-content {
  .header-content-wrapper {  }
  .header-content { min-height: 360px; margin-top: 64px; margin-bottom: 64px;
    display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;
  }
  .header-title { font-size: 24px; font-weight: 600;
    span { display: block; } 
  }
  .header-subtitle { font-size: 20px; font-weight: 600; }
  .header-actions { margin-top: 32px;
    .btn { margin-bottom: 24px; }
    .header-phone { margin-top: 16px; font-size: 20px; font-weight: 600;
      a { color: $color-text-light; }
    }
    .header-email { margin-top: 16px; font-size: 20px; font-weight: 600;
      a { color: $color-text-light; }
    }
    .header-working-hours, .header-working-hours { font-size: 14px; }
  }
  .header-working { color: #00c853; display: flex; align-items: center; justify-content: center; opacity: 0;
    &.open-now { opacity: 1; }
    .working-icon { width: 8px; height: 8px; border-radius: 50%; background: #00c853; margin-right: 8px; }
  }

  .header-social { margin-top: 16px; margin-left: -4px; margin-right: -4px;
    .services-icons { transform: scale(1.33); margin-left: 4px; margin-right: 4px; }
  }

  @include breakpoint(480px) {
    .header-title { font-size: 34px; }
    .header-subtitle { font-size: 24px; }
    .header-content { min-height: 560px; }
  }
  @include breakpoint($md) {
    .header-content-wrapper { display: flex; justify-content: flex-end; }
    .header-content { width: 50%; align-items: flex-start; text-align: left; }
    .header-actions { margin-bottom: 32px; }
    .header-working { justify-content: flex-start; }
  }
  @include breakpoint($lg) {
    .header-actions {
      .header-phone { font-size: 24px; }
      .header-email { font-size: 24px; }
      .header-working-hours, .header-working-hours { font-size: 16px; }
    } 
  }
}

.header-bg-image.navbar-fixed {
  #header-bg {
    background-image: url('../img/header-bg-grid.png'), linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%,rgba(0, 0, 0, 0) $navbar), url('../img/header-bg.jpg') !important;
    background-position: top left, top left, center center !important; background-size: auto, auto, cover !important;
  }
  // old
  /* 
  #header-bg { background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%,rgba(0, 0, 0, 0) $navbar), linear-gradient(to bottom, rgba(0, 0, 0, 0.45) 0%,rgba(0, 0, 0, 0.45) 100%),
    url(../img/header-bg-xs.jpg) center center; background-size: auto, auto, cover;
  }
  @include breakpoint($md) {
    #header-bg { background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%,rgba(0, 0, 0, 0) $navbar), linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%,rgba(0, 0, 0, 0.25) 100%),
      url(../img/header-bg.jpg) center center; background-size: auto, auto, cover;
    }
  }
  */

  /*
  @include breakpoint($lg) {
    #header-bg {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%,rgba(0, 0, 0, 0) $navbar), url('../img/header-bg.jpg') !important;
      background-position: top left, center center !important; background-size: auto, cover !important;
    }
  }
  */
}

/* blocks */

.block-offer { background: color($color-primary-base, 300);
  .block-title { color: $color-text; }
  .item { margin: 0 auto; max-width: 360px; padding: 24px 12px; display: flex; height: 100%; align-items: center;
    .material-icons { font-size: 48px; line-height: 48px; color: color($color-primary-base, 800); width: 48px; height: 48px; margin-right: 24px; } 
    .title { margin-top: 0; margin-bottom: 0; }
  }

  @include breakpoint($md) {
    .item { padding-top: 32px; padding-bottom: 32px; }
  }
}

#projects { background-color: color($grey, 200); background-image: url('../img/projects-bg.jpg');
  .block-title { color: $color-text; }
  .block-subtitle { display: flex; justify-content: center; text-align: left;
    .icon { margin-right: 12px; margin-top: 2px; }
  }
  .product-list-card { margin-bottom: 24px;
    .card-title { display: flex; justify-content: space-between; flex-wrap: wrap; align-items: center; }
    .title { margin-right: 24px; }
    .price { margin: 8px 0; font-weight: 600; }
    .subtitle { width: 100%; }
  }
  .photoswipe {
    &:after { color: rgba($color-text, .5); }
  }

  #projects-2 { visibility: hidden; height: 0; overflow: hidden;
    &.visible { visibility: visible; height: auto; overflow: visible; margin-top: 32px; }
  }
}

.block-building { color: $color-text-light;
  .col-left { background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url('../img/building-bg.jpg');
    background-color: $color-accent; background-position: top left, center center; background-repeat: repeat, no-repeat; background-size: auto, cover;   
  }
  .col-right { background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%),
    linear-gradient(to bottom, color($color-accent-base, a200) 0%, color($color-accent-base, a200) 100%);
  }
  .col-left-content { margin: 64px 0;
    .title { font-size: 24px; font-weight: 600; margin-left: 40px;
      span { display: block; }
    }
  }
  .col-right-content { margin: 64px 0;
    .benefits-list li { margin: 8px 0; }
  }
  @include breakpoint($xs) {
    .col-left-content { margin: 64px 48px; max-width: 544px;  }
    .col-right-content { margin: 64px 48px; max-width: 544px; }
  }
  @include breakpoint($sm) {
    .col-left-content { margin: 64px auto; }
    .col-right-content { margin: 64px auto;
      .benefits-list { font-size: 18px; }
    }
  }
  @include breakpoint($md) {
    .col-left { display: flex; justify-content: flex-end; }
    .col-right { display: flex; align-items: stretch; }
    .col-left-content { margin: 96px 48px; min-height: 240px; display: flex; flex-direction: column; justify-content: center; width: 100%; }
    .col-right-content { margin: 96px 48px; display: flex; align-items: center; }
  }
}

.block-addition { position: relative; overflow: hidden; background: $color-bg-card; //background: color($color-accent-base, 50);
  &:after { @extend .material-icons; content: "check_circle"; position: absolute; top: 50%; color: color($color-accent-base, a700); font-size: 192px; margin-top: -96px; right: -48px; opacity: 0.1; z-index: 1; }
  .content { z-index: 2; }
  .addition-list { font-size: 18px; max-width: 720px; margin: 24px auto;
    ul { list-style: none; padding-left: 0; }
    li { margin: 8px 0; padding-left: 56px; position: relative;
      &:before { @extend .material-icons; content: "done"; font-size: 32px; position: absolute; margin: -4px 0 0 -48px; color: color($color-accent-base, a700); }
    }
  }
  @include breakpoint($xs) {
    .benefits-list li { padding-left: 96px;
      &:before { margin: -4px 0 0 -64px; }
    }
  }
}

.block-works { background: color($color-primary-base, 800);
  .block-title { color: $color-text-light; }
  @include breakpoint($lg) {
    .col-lg-custom { max-width: 20%; flex: 0 0 20%; }
  }

  #works-2 { visibility: hidden; height: 0; overflow: hidden;
    &.visible { visibility: visible; height: auto; overflow: visible; margin-top: 32px; }
  }
}

.block-special { background-image: url('../img/special-bg.jpg'); background-color: color($color-primary-base, 300);
  background-position: center center; background-repeat: no-repeat; background-size: cover; color: color($color-primary-base, 800);
  .special-text { background: $color-bg; border-radius: 2px; @extend .z-level-2; padding: 48px 24px; position: relative;
    // OV! background: color($color-accent-base, 400); 
    &:before, &:after, .wrapper:before, .wrapper:after { display: block; width: 24px; height: 24px; position: absolute; @extend .material-icons; content: "add_circle"; color: rgba(#000, 0.7); }
    &:before { top: 16px; left: 16px; transform: rotate(25deg); }
    .wrapper:before { top: 16px; right: 16px; transform: rotate(15deg); }
    &:after { bottom: 16px; left: 16px; transform: rotate(55deg); }
    .wrapper:after { bottom: 16px; right: 16px; transform: rotate(5deg); }
  }
  .wrapper { max-width: 480px; margin: 0 auto; }
  .text { font-size: 18px; }
  @include breakpoint($xs) {
    .special-text { padding: 48px 48px; }
  }
  @include breakpoint($sm) {
    .special-text { padding: 64px 80px; }
  }
}

.block-special-new { background: color($color-primary-base, 300);
  .block-title { color: $color-text; }
  .item { position: relative; background-color: $color-bg-card; background-position: center center; background-repeat: no-repeat; background-size: cover;
    max-width: 360px; margin-left: auto; margin-right: auto; margin-top: 12px; margin-bottom: 12px;
    &:before { display: block; content: ''; width: 100%; height: 0; padding-bottom: 67%; background-color: rgba(color($grey, 900), .5); }
    &.item-1 { background-image: url('../img/special-01.jpg'); }
    &.item-2 { background-image: url('../img/special-02.jpg'); }
    &.item-3 { background-image: url('../img/special-03.jpg'); }
    .item-content { position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; align-items: center; text-align: center; flex-direction: column; padding: 24px; }
    .icon { width: 80px; height: 80px; padding: 16px; background: $color-text-light; border-radius: 50%; color: color($color-primary-base, 800); margin-top: 24px;
      i { font-size: 48px; height: 48px; width: 48px; }
    }
    .title { color: $color-text-light; max-width: 320px; margin-left: auto; margin-right: auto; }
  }
}

.block-contacts { font-size: 18px; background: $color-bg-card;
  .block-content { max-width: 480px; margin: 0 auto; }
  .contact-phone { padding-left: 64px; position: relative; margin-top: 24px; margin-bottom: 24px;
    &:before { @extend .material-icons; content: "phone"; position: absolute; left: 16px; top: 0; color: rgba($color-primary, 0.5); }
    div { margin-bottom: 8px; }
    a { font-weight: 600; }
  }
  .contact-chat { padding-left: 64px; position: relative; margin-top: 24px; margin-bottom: 24px;
    &:before { @extend .material-icons; content: "chat"; position: absolute; left: 16px; top: 0; color: rgba($color-primary, 0.5); }
    a { font-weight: 600; }
  }
  .contact-email { padding-left: 64px; position: relative; margin-top: 24px; margin-bottom: 24px;
    &:before { @extend .material-icons; content: "email"; position: absolute; left: 16px; top: 0; color: rgba($color-primary, 0.5); }
    a { font-weight: 600; }
  }
  .contact-place { padding-left: 64px; position: relative; margin-top: 24px; margin-bottom: 24px;
    &:before { @extend .material-icons; content: "place"; position: absolute; left: 16px; top: 0; color: rgba($color-primary, 0.5); }
  }
}

.block-showroom { background: color($grey, 300);
  .block-title { color: $color-text; }
}

.block-map { background: color($grey, 600); position: relative; z-index: 1;
  #gmap { height: 320px; width: 100%; pointer-events: none; }
  @include breakpoint($md) {
    #gmap { pointer-events: auto; height: 480px; }
  }
}

.block-workflow { background: color($color-primary-base, 200);
  .block-title { color: $color-text; }
  .steps-list { counter-reset: items; }
  .item { padding: 8px 24px 56px; text-align: center; max-width: 320px; margin-left: auto; margin-right: auto;
    background: linear-gradient(color($color-primary-base, 500), color($color-primary-base, 500)) no-repeat; background-size: 1px 32px; background-position: bottom center;
  }
  .icon { width: 96px; height: 96px; margin-left: auto; margin-right: auto; background: $color-accent; border-radius: 50%; border: 24px solid color($color-primary-base, 200);
    color: $color-text-light; font-size: 24px; line-height: 48px; font-weight: 600;
    &:before { content: counter(items); counter-increment: items; }
  }
  @include breakpoint(max-width $max-sm) {
    .item:last-child { background: none; }
  }
  @include breakpoint($sm) {
    .steps-list { display: flex; flex-wrap: wrap; justify-content: center; align-items: stretch; }
    .item { width: 50%; flex: 0 0 auto; margin-left: 0; margin-right: 0; padding: 32px 24px; }
    .break-sm { width: 100%; }
  }
  @include breakpoint($sm $max-md) {
    .item {
      &:nth-child(odd) { background-size: 50% 1px; background-position: 100% 80px; }
      &:nth-child(even) { background-size: 50% 1px; background-position: 0 80px; }
    }
  }
  @include breakpoint($md) {
    .item { width: 25%; background-size: 100% 1px; background-position: 0 80px;
      &:first-child, &:nth-child(9) { background-size: 50% 1px; background-position: 100% 80px; }
      &:last-child, &:nth-child(6) { background-size: 50% 1px; background-position: 0 80px; }
    }
    .break-sm { width: auto; }
    // .break-md { width: 100%; }
  }
}

.slick-slider { display: none;
  &.slick-initialized { display: block; }

  .slick-arrow { width: 32px; height: 32px; background: color($color-primary-base, 600); z-index: 2; padding: 4px;
    &:before { @extend .material-icons; color: $color-text-light; width: 24px; height: 24px; display: block; }
    &:focus { background: color($color-primary-base, 600); }
    &:hover { background: color($color-primary-base, 700); }
  }
  .slick-prev { left: -16px;
    &:before { content: 'arrow_back'}
  }
  .slick-next { right: -16px;
    &:before { content: 'arrow_forward'}
  }
}

.block-materials { background: color($color-primary-base, 400); overflow: hidden;
  .block-title { color: $color-text; }
  .col { max-width: 360px; margin: 24px auto 32px; }
  .item { background: $color-bg-card;
    img { display: block; width: 100%; height: auto; }
  }
  .list-title { margin-bottom: 16px; color: $color-text; }

  @include breakpoint($sm) {
    .cols-2 { display: flex; justify-content: center; }
    .col { max-width: 408px; margin: 0 12px; padding: 24px; width: calc(50% - 24px); }
  }
}

.block-doors { background: color($color-primary-base, 200); }

/* footer */

#footer { background: color($color-primary-base, 300); }
