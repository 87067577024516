@charset "UTF-8";

// overrided variables


$color-primary-base: $grey;
$color-accent-base: $red;

$color-primary: color($color-primary-base, 700);
$color-primary-hover: color($color-primary-base, 800);
$color-primary-active: color($color-primary-base, 900);

$color-accent: color($color-accent-base, 900);
$color-accent-hover: color($color-accent-base, a700);
$color-accent-active: lighten(color($color-accent-base, a700), 3);

$font-family: 'Montserrat', sans-serif;
